import { ContextServType, ContextUser } from 'types/ContextServType';
import { config } from '@grafana/runtime';
import { OrgRole } from '@grafana/data';

const getContextSrv: () => ContextServType | null | undefined = () => require('grafana/app/core/core').contextSrv;

export const getContextUser = (): ContextUser | undefined => {
  return getContextSrv()?.user;
};

export const isUserAdmin = (): boolean => {
  return config.bootData.user.orgRole === OrgRole.Admin;
};

export const isUserEditor = (): boolean => {
  return config.bootData.user.orgRole === OrgRole.Editor;
};

export const isUserLightTheme = () => {
  return config.bootData.user.theme === 'light';
};
