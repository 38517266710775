// Prefixes the route with the base URL of the plugin
import pluginJson from '../plugin.json';
import { CloudProvider } from 'types/CloudProvider';

export const PLUGIN_ID = pluginJson.id;
export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;
export const PLUGIN_PROXY_URL = `/api/plugin-proxy/${pluginJson.id}`;

export function prefixRoute(provider?: CloudProvider, route = ''): string {
  return provider ? `${PLUGIN_BASE_URL}/${provider}${route ? `/${route}` : ''}` : PLUGIN_BASE_URL;
}
